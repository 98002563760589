import styled from 'styled-components'

import {Select} from './components'
import Logo from './logo'
import {tokens} from './tokens'
import type {CheckStatus, FilterType, Mode, SemanticTokenType} from './types'
import {px} from './utils'

const Container = styled.div`
    align-items: center;
    display: flex;
    gap: ${tokens.spacingXMedium};
`

const TitleAndModeSelector = styled.div`
    display: flex;
    gap: ${tokens.spacingMedium};
    align-items: center;
    margin-right: auto;
`

const Title = styled.h3`
    color: ${tokens.colorContentStatic};
    font-size: ${tokens.fontSizeXLarge};
    margin: 0 auto 0 0;
`

const TestResultsBox = styled.div`
    font-size: ${tokens.fontSizeRegular};
    display: flex;
    align-items: stretch;
    height: ${tokens.heightInteractiveSmall};
    overflow: hidden;
`

const TestResultsBoxLabel = styled.span`
    color: ${tokens.colorContentStaticQuiet};
    font-weight: ${tokens.fontWeightBold};
    border-top-left-radius: ${tokens.borderRadiusMedium};
    border-bottom-left-radius: ${tokens.borderRadiusMedium};
    border: ${tokens.borderStatic};
    display: flex;
    align-items: center;
    padding: 0 ${tokens.spacingMedium};
    background-color: ${tokens.colorBackgroundStaticLouder};
`

const ScoreToggleButton = styled.button<{$color: string; $active: boolean}>`
    background: none;
    font-weight: ${tokens.fontWeightBold};
    font-size: ${tokens.fontSizeRegular};
    padding: 0 ${tokens.spacingMedium};
    cursor: pointer;
    color: ${({$color}) => $color};
    box-shadow: ${({$active}) => ($active ? `inset 0 0 ${px(3)} #999` : 'none')};
    border: ${tokens.borderStatic};
    border-left: none;

    &:hover {
        text-decoration: ${({$active}) => ($active ? 'none' : 'underline')};
    }

    &:last-child {
        border-top-right-radius: ${tokens.borderRadiusMedium};
        border-bottom-right-radius: ${tokens.borderRadiusMedium};
    }
`

export const statusToTextColorMapping: Record<CheckStatus, string> = {
    pass: tokens.colorContentPositive,
    fail: tokens.colorContentNegative,
    'not-available': tokens.colorContentStaticQuiet,
    'not-defined': tokens.colorContentStaticQuiet
}

export const ScoreText = ({score, status}: {score: ScoreType; status: CheckStatus}) => {
    const current = score.current[status]
    const stored = score.stored[status]
    const change = current - stored
    const changeText = change > 0 ? `+${change}` : change

    return (
        <span style={{color: statusToTextColorMapping[status]}}>
            {current}
            {current !== stored && <span> ({changeText})</span>}
        </span>
    )
}

const Score = ({
    score,
    onClick,
    active,
    status
}: {
    score: ScoreType
    status: keyof ScoreTypeObject
    onClick: () => void
    active: boolean
}) => {
    return (
        <ScoreToggleButton
            title={active ? 'Clear filter' : `Filter by status: ${status}`}
            $color={statusToTextColorMapping[status]}
            $active={active}
            onClick={onClick}
        >
            <ScoreText score={score} status={status} />
        </ScoreToggleButton>
    )
}

// eslint-disable-next-line @typescript-eslint/no-shadow
const getScore = (tokens: SemanticTokenType[], type: 'apca' | 'wcag'): ScoreType => {
    const scores = {
        current: {pass: 0, fail: 0, 'not-available': 0, 'not-defined': 0},
        stored: {pass: 0, fail: 0, 'not-available': 0, 'not-defined': 0}
    }
    tokens.forEach((token) => {
        token.checks?.forEach((check) => {
            scores.current[check[type].current.status] += 1
            scores.stored[check[type].stored.status] += 1
        })
    })

    return scores
}

type ScoreTypeObject = {pass: number; fail: number; 'not-available': number; 'not-defined': number}
type ScoreType = {
    current: ScoreTypeObject
    stored: ScoreTypeObject
}

export const Header = ({
    mode,
    displayTokens,
    setMode,
    toggleFilter,
    filter
}: {
    mode: Mode
    setMode: (mode: Mode) => void
    displayTokens: SemanticTokenType[]
    toggleFilter: (filter: FilterType) => void
    filter: FilterType
}) => {
    const apca = getScore(displayTokens, 'apca')
    const wcag = getScore(displayTokens, 'wcag')

    return (
        <Container>
            <TitleAndModeSelector>
                <Logo />
                <Title>Stellaris</Title>
                <Select
                    value={mode}
                    onChange={(e) => setMode(e.target.value as Mode)}
                    title="Change color scheme"
                >
                    <option value="light">Light</option>
                    <option value="dark">Dark</option>
                </Select>
            </TitleAndModeSelector>
            <TestResultsBox>
                <TestResultsBoxLabel>APCA</TestResultsBoxLabel>
                <Score
                    status="pass"
                    active={filter === 'acpa:pass'}
                    score={apca}
                    onClick={() => toggleFilter('acpa:pass')}
                />
                <Score
                    status="fail"
                    active={filter === 'acpa:fail'}
                    score={apca}
                    onClick={() => toggleFilter('acpa:fail')}
                />
                <Score
                    status="not-available"
                    active={filter === 'apca:not-available'}
                    score={apca}
                    onClick={() => toggleFilter('apca:not-available')}
                />
            </TestResultsBox>
            <TestResultsBox>
                <TestResultsBoxLabel>WCAG</TestResultsBoxLabel>
                <Score
                    status="pass"
                    active={filter === 'wcag:pass'}
                    score={wcag}
                    onClick={() => toggleFilter('wcag:pass')}
                />
                <Score
                    status="fail"
                    active={filter === 'wcag:fail'}
                    score={wcag}
                    onClick={() => toggleFilter('wcag:fail')}
                />
                <Score
                    status="not-available"
                    active={filter === 'wcag:not-available'}
                    score={wcag}
                    onClick={() => toggleFilter('wcag:not-available')}
                />
            </TestResultsBox>
        </Container>
    )
}
