import type React from 'react'
import {Helmet as ReactHelmet} from 'react-helmet'

// Fix types after update to React 18 which removes children from React.FC props
// https://github.com/nfl/react-helmet/issues/646#issuecomment-1221314650
type HelmetProps = React.ComponentProps<typeof ReactHelmet> & {children: React.ReactNode}

export const Helmet = ({children, ...rest}: HelmetProps) => (
    <ReactHelmet {...rest}>{children}</ReactHelmet>
)
