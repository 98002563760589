import chroma from 'chroma-js'
import type React from 'react'
import styled from 'styled-components'

import {Delete} from '@pleo-io/telescope-icons'
import coreColors from '@pleo-io/telescope-tokens/dist/tokens/color.json'

import {IconButton} from './components'
import {tokens} from './tokens'
import type {CoreTokenType} from './types'
import {px} from './utils'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacingMedium};
    margin-bottom: ${tokens.spacingXMedium};
    padding-bottom: ${tokens.spacingXMedium};
    border-bottom: ${tokens.borderStatic};
`

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: ${tokens.spacingMedium};
`

const TokenName = styled.span`
    color: ${tokens.colorContentStatic};
    font-size: ${tokens.fontSizeRegular};
    font-weight: ${tokens.fontWeightBold};
`

const TokenValue = styled.span`
    color: ${tokens.colorContentStatic};
    font-size: ${tokens.fontSizeRegular};
`

const StoredValue = styled.span`
    color: ${tokens.colorContentStaticQuiet};
    text-decoration: line-through;
`

const TextFieldWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    background-color: ${tokens.colorBackgroundStaticLoudest};
    border-radius: ${tokens.borderRadiusMedium};
`

const TextFieldPostfix = styled.label`
    font-size: ${tokens.fontSizeRegular};
    font-weight: ${tokens.fontWeightBold};
    color: ${tokens.colorContentStaticQuiet};
    position: absolute;
    height: ${tokens.heightInteractiveSmall};
    width: ${tokens.heightInteractiveSmall};
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
`

const TextFieldInput = styled.input`
    color: ${tokens.colorContentInteractive};
    width: 100%;
    font-size: ${tokens.fontSizeRegular};
    padding-left: ${tokens.heightInteractiveSmall};
    height: ${tokens.heightInteractiveSmall};
    border: none;
    background: none;

    &:hover:not(:focus) {
        text-decoration: underline;
    }
`

interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    postfix?: string
}
const TextField = ({postfix, ...rest}: TextFieldProps) => (
    <TextFieldWrapper>
        <TextFieldPostfix>{postfix}</TextFieldPostfix>
        <TextFieldInput {...rest} />
    </TextFieldWrapper>
)

const ColorInput = styled.input.attrs({type: 'color'})`
    padding: 0;
    inline-size: ${px(25)};
    block-size: ${px(28)};
    background: none;
    border: none;
    cursor: pointer;
`

const LCHPicker = styled.div`
    display: flex;
    gap: ${tokens.spacingMedium};
`

export const CoreToken = ({
    token,
    onUpdateValue,
    onReset,
    modified
}: {
    token: CoreTokenType
    onUpdateValue: (value: string) => void
    onReset: () => void
    modified: boolean
}) => {
    const [l, c, h] = chroma(token.value).oklch()

    const onChangeOklch = (type: 'l' | 'c' | 'h', value: string) => {
        const oklch = {l, c, h}
        oklch[type] = Number(value)
        onUpdateValue(chroma.oklch(oklch.l, oklch.c, oklch.h).hex())
    }

    return (
        <Wrapper id={token.name}>
            <Header>
                <ColorInput value={token.value} onChange={(e) => onUpdateValue(e.target.value)} />
                <TokenName>{token.name}</TokenName>
                {modified && <StoredValue>{coreColors[token.name].value}</StoredValue>}
                <TokenValue>({token.value})</TokenValue>
                {modified && (
                    <IconButton title="Revert change" onClick={onReset}>
                        <Delete size={16} />
                    </IconButton>
                )}
            </Header>
            <LCHPicker>
                <TextField
                    postfix="L"
                    defaultValue={l.toFixed(2)}
                    onChange={(e) => onChangeOklch('l', e.target.value)}
                />
                <TextField
                    postfix="C"
                    defaultValue={c.toFixed(2)}
                    onChange={(e) => onChangeOklch('c', e.target.value)}
                />
                <TextField
                    postfix="H"
                    defaultValue={h.toFixed(2)}
                    onChange={(e) => onChangeOklch('h', e.target.value)}
                />
            </LCHPicker>
        </Wrapper>
    )
}
