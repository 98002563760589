import styled, {css} from 'styled-components'

import {tokens} from './tokens'
import {px} from './utils'

export const Anchor = styled.a`
    color: currentColor;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`

export const Table = styled.table`
    width: 100%;
    text-align: left;
    border-collapse: collapse;
`

export const TBody = styled.tbody``

const stripedBackground = css`
    background-color: ${tokens.colorBackgroundStaticLoudest};
    background-size: ${px(10)} ${px(10)};
    background-image: repeating-linear-gradient(
        -45deg,
        ${tokens.colorBackgroundStaticLoudest} 0,
        ${tokens.colorBackgroundStaticLoudest} ${px(1)},
        ${tokens.colorBackgroundStatic} 0,
        ${tokens.colorBackgroundStatic} 50%
    );
`

export const Tr = styled.tr<{$modifed?: boolean}>`
    border-bottom: ${tokens.borderStatic};
    background: ${({$modifed}) => ($modifed ? stripedBackground : 'transparent')};
`

export const Td = styled.td`
    padding: ${tokens.spacingMedium} 0;
    font-size: ${tokens.fontSizeRegular};
    vertical-align: middle;

    &:not(:last-child) {
        padding-right: ${tokens.spacingMedium};
    }
`

export const Th = styled.th`
    padding: ${px(18)} 0;
    font-size: ${tokens.fontSizeRegular};
    font-weight: ${tokens.fontWeightRegular};
    color: ${tokens.colorContentStaticQuiet};

    &:not(:last-child) {
        padding-right: ${tokens.spacingMedium};
    }
`

export const THead = styled.thead``

export const Button = styled.button<{$variant?: 'primary' | 'secondary'}>`
    background: ${({$variant}) =>
        $variant === 'primary' ? tokens.colorBackgroundInteractiveInverse : 'transparent'};
    color: ${({$variant}) =>
        $variant === 'primary'
            ? tokens.colorContentInteractiveInverse
            : tokens.colorContentInteractive};
    padding: 0 ${tokens.spacingMedium};
    height: ${tokens.heightInteractiveRegular};
    font-size: ${tokens.fontSizeRegular};
    border: ${px(1)} solid;
    border-color: ${({$variant}) => ($variant === 'primary' ? 'black' : 'transparent')};
    font-weight: ${tokens.fontWeightBold};
    border-radius: ${tokens.borderRadiusMedium};
    cursor: pointer;
    transition: ${tokens.transitionSpeedRegular};

    &:disabled {
        cursor: not-allowed;
        background-color: transparent;
        color: ${tokens.colorContentInteractiveDisabled};
        border: ${({$variant}) =>
            $variant === 'primary' ? tokens.borderInteractiveDisabled : tokens.borderTransparent};
    }
`

export const IconButton = styled.button`
    color: ${tokens.colorContentInteractive};
    background: transparent;
    padding: 0;
    font-weight: ${tokens.fontWeightBold};
    border: none;
    cursor: pointer;
`

export const Select = styled.select`
    appearance: none;
    color: ${tokens.colorContentInteractive};
    font-size: ${tokens.fontSizeRegular};
    padding: 0 ${tokens.spacingMedium};
    height: ${tokens.heightInteractiveSmall};
    border-radius: ${tokens.borderRadiusMedium};
    border: ${tokens.borderInteractive};
    cursor: pointer;
    background: ${tokens.colorBackgroundInteractive};
    transition: ${tokens.transitionSpeedRegular};

    &:hover {
        border: ${tokens.borderInteractiveHover};
    }
`
