import styled from 'styled-components'

import {Button, Link, Text, tokens} from '@pleo-io/telescope'
import {NewTab} from '@pleo-io/telescope-icons'

export const Wrapper = styled.div`
    margin-top: ${tokens.spacing48};
    padding-top: ${tokens.spacing48};
    border-top: ${tokens.borderStatic};
`

const FeedbackFooter = ({path, editPageUrl}: {path: string; editPageUrl?: string}) => (
    <Wrapper>
        <Text variant="large-accent" weight="bold" space={10}>
            Didn't find what you were looking for?
        </Text>
        <Text space={32}>
            Let us know in{' '}
            <Link href="https://getpleo.slack.com/archives/CPCFSJK1R" target="_blank">
                #team-design-systems
            </Link>{' '}
            or{' '}
            <Link href="https://linear.app/pleo/team/DO/new" target="_blank">
                make a request
            </Link>
            .
        </Text>
        <Button
            variant="secondary"
            IconRight={NewTab}
            title="Edit this page"
            target="_blank"
            href={
                editPageUrl ||
                `https://github.com/pleo-io/telescope/edit/master/docs/pages${path}.mdx`
            }
            rel="noreferrer"
        >
            Edit this page
        </Button>
    </Wrapper>
)

export default FeedbackFooter
