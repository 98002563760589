import {Link as GatsbyLink} from 'gatsby'
import styled from 'styled-components'

import {focusRing, tokens} from '@pleo-io/telescope'

import {minWidthDesktop} from '../../tokens'

const Wrapper = styled.div`
    display: inline-flex;
    margin: ${tokens.spacing8} 0 ${tokens.spacing24} 0;

    @media (min-width: ${minWidthDesktop}) {
        width: 100%;
    }
`

const PlatformLink = styled(GatsbyLink)<{$active: boolean}>`
    ${focusRing('regular')};
    overflow: hidden;
    padding: ${tokens.spacing4} ${tokens.spacing12};
    text-align: center;
    cursor: ${({$active}) => ($active ? 'default' : 'pointer')};
    font-size: ${tokens.fontMedium};
    font-weight: ${({$active}) => ($active ? tokens.fontWeightSemibold : tokens.fontWeightRegular)};
    background: ${({$active}) =>
        $active ? tokens.colorBackgroundInteractiveSelected : 'transparent'};
    color: ${({$active}) =>
        $active ? tokens.colorContentInteractiveSelected : tokens.colorContentInteractive};
    border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractiveLoud};

    &:first-child {
        border-right: none;
        border-top-left-radius: ${tokens.arc8};
        border-bottom-left-radius: ${tokens.arc8};
    }

    &:last-child {
        border-left: none;
        border-top-right-radius: ${tokens.arc8};
        border-bottom-right-radius: ${tokens.arc8};
    }

    &:hover {
        text-decoration: ${({$active}) => ($active ? 'none' : 'underline')};
    }

    @media (min-width: ${minWidthDesktop}) {
        flex: 1;
    }
`

const PlatformToggle = ({platform}: {platform: 'web' | 'mobile'}) => (
    <Wrapper>
        <PlatformLink to="/components/web/overview/overview.overview" $active={platform === 'web'}>
            Web
        </PlatformLink>
        <PlatformLink
            to="/components/mobile/overview/overview.overview"
            $active={platform === 'mobile'}
        >
            Mobile
        </PlatformLink>
    </Wrapper>
)

export default PlatformToggle
