import styled from 'styled-components'

import {tokens} from './tokens'
import {px} from './utils'

const LogoContainer = styled.div`
    width: ${px(30)};
    height: ${px(30)};
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    padding-inline: ${px(1)};
    box-sizing: border-box;
    border-radius: ${tokens.borderRadiusCircle};
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transform: rotate(45deg);
`

const Line = styled.div`
    width: ${px(2.5)};
    height: 100%;
`

const Logo = () => {
    return (
        <LogoContainer>
            <Line style={{background: '#4588e3'}} />
            <Line style={{background: '#6abe84'}} />
            <Line style={{background: '#fb6060'}} />
            <Line style={{background: '#fbe360'}} />
        </LogoContainer>
    )
}

export default Logo
