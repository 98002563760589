import {Link as GatsbyLink} from 'gatsby'
import styled from 'styled-components'

import {Inline, NakedButton, px, Text, tokens} from '@pleo-io/telescope'
import {BurgerMenu, Close} from '@pleo-io/telescope-icons'

import {maxWidthContent, maxWidthTablet} from '@/tokens'

import {ThemeSwitcher} from '../theme-switcher/theme-switcher'

export const heightHeaderMobile = px(70)
export const zIndexHeaderMobile = 3

const Wrapper = styled.div<{$isActive: boolean}>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${zIndexHeaderMobile};
    display: none;
    width: 100%;
    background-color: ${({$isActive}) =>
        $isActive ? tokens.colorBackgroundStaticLoudest : tokens.colorBackgroundStatic};
    border-bottom: ${tokens.borderStatic};

    @media (max-width: ${maxWidthTablet}) {
        display: block;
    }
`

const Inner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: ${maxWidthContent};
    height: ${heightHeaderMobile};
    margin: 0 auto;
    padding: 0 ${tokens.spacing28};
`

const MenuButton = styled(NakedButton)`
    width: ${tokens.spacing44};
    height: ${tokens.spacing44};
    background-color: ${tokens.colorBackgroundStaticLoudest};
    border-radius: ${tokens.arc99999};

    svg {
        color: ${tokens.colorContentInteractive};
    }
`

type Props = {
    isNavigationVisible: boolean
    setIsNavigationVisible: (isVisible: boolean) => void
}
const HeaderMobile = ({isNavigationVisible, setIsNavigationVisible}: Props) => (
    <Wrapper $isActive={isNavigationVisible}>
        <Inner>
            <GatsbyLink to="/">
                <Text variant="xlarge-accent" weight="bold">
                    Telescope
                </Text>
            </GatsbyLink>
            <Inline space={8} alignItems="center">
                <ThemeSwitcher />
                <MenuButton
                    aria-label="Main menu"
                    onClick={() => setIsNavigationVisible(!isNavigationVisible)}
                >
                    {isNavigationVisible ? <Close /> : <BurgerMenu />}
                </MenuButton>
            </Inline>
        </Inner>
    </Wrapper>
)

export default HeaderMobile
