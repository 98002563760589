import {graphql, useStaticQuery} from 'gatsby'
import type {FC} from 'react'
import {useCallback, useEffect, useRef, useState} from 'react'

import {Link} from '@pleo-io/telescope'
import {NewTab} from '@pleo-io/telescope-icons'

import type {NavigationQuery} from '@/graphql-types'
import {useLocalStorage} from '@/hooks/use-local-storage'
import {sort} from '@/utilities/sort'

import MobileNavigation from './header-mobile'
import * as s from './navigation.styles'
import PlatformToggle from './platform-toggle'
import Section, {SectionTitleLink, SectionTitleLinkText} from './section'

import {isMobilePage, usePlatform} from '../../hooks/use-platform'
import {Search} from '../search/search'
import {ThemeSwitcher} from '../theme-switcher/theme-switcher'

interface Props {
    currentPath: string
}

const LinkItemBadge = ({status}: {status?: string}) => {
    if (status === 'new') {
        return (
            <s.Status variant="discover" loud>
                New
            </s.Status>
        )
    } else if (status === 'draft') {
        return (
            <s.Status variant="warning" loud>
                Draft
            </s.Status>
        )
    }

    return null
}

type Node = {
    id: string
    path: string
    context: {
        frontmatter: {
            title: string
            status?: string
        }
    }
}

type LinkProps = {
    nodes: Node[]
    currentPath: string
}
const LinkItems = ({nodes, currentPath}: LinkProps) => (
    <s.LinkItems>
        {nodes.sort(sort((node) => node.context.frontmatter.title.toLowerCase())).map(
            ({
                id,
                path,
                context: {
                    frontmatter: {title, status}
                }
            }) => {
                const isActiveParent = !!currentPath.match(path.slice(0, path.lastIndexOf('/') + 1))
                return (
                    <s.LinkItem key={id}>
                        <s.Link
                            className={isActiveParent ? 'js-active-parent' : undefined}
                            to={path}
                            $isActiveParent={isActiveParent}
                        >
                            <s.LinkTitle>{title}</s.LinkTitle>
                            <LinkItemBadge status={status} />
                        </s.Link>
                    </s.LinkItem>
                )
            }
        )}
    </s.LinkItems>
)

export const Navigation: FC<Props> = ({currentPath}) => {
    const platform = usePlatform()
    const [scrollPosition, setScrollPosition] = useLocalStorage<number>(
        'telescope-navigation-scroll-position',
        0
    )
    const contentRef = useRef<HTMLElement>(null)
    const [isNavigationVisible, setIsNavigationVisible] = useState(false)
    const data = useStaticQuery<NavigationQuery>(graphql`
        query Navigation {
            other: allSitePage(filter: {path: {regex: "/documentation/.+/*home/"}}) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                        }
                    }
                }
            }
            tokens: allSitePage(
                filter: {context: {frontmatter: {section: {eq: "Design Tokens"}}}}
            ) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                        }
                    }
                }
            }
            components: allSitePage(
                filter: {context: {frontmatter: {section: {eq: "Components"}}}}
            ) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                            status
                        }
                    }
                }
            }
            icons: allSitePage(filter: {path: {regex: "/icons/.+(about|introduction)/"}}) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                        }
                    }
                }
            }
            graphicElements: allSitePage(
                filter: {path: {regex: "/graphic-elements/.+(overview|about)/"}}
            ) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                        }
                    }
                }
            }
            foundations: allSitePage(filter: {path: {regex: "/foundations/.+(overview)/"}}) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                        }
                    }
                }
            }
            patterns: allSitePage(filter: {path: {regex: "/patterns/.+(about|introduction)/"}}) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                        }
                    }
                }
            }
            illustrations: allSitePage(filter: {path: {regex: "/illustrations/.+(overview)/"}}) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                        }
                    }
                }
            }
        }
    `)

    const scroll = useCallback(() => {
        contentRef.current?.scrollTo(0, scrollPosition)
    }, [scrollPosition])

    useEffect(() => {
        scroll()
        const current = contentRef.current

        current?.addEventListener('scroll', () => {
            setScrollPosition(current?.scrollTop || 0)
        })

        return () => {
            current?.removeEventListener('scroll', () => {})
        }
    }, [setScrollPosition, scroll])

    const componentPages = data.components.nodes.filter((n) => {
        if (!n.path.match('.guidelines')) {
            return false
        }

        if (platform === 'mobile') {
            return isMobilePage(n.path)
        }

        return !isMobilePage(n.path)
    })

    return (
        <>
            <MobileNavigation
                isNavigationVisible={isNavigationVisible}
                setIsNavigationVisible={setIsNavigationVisible}
            />
            <s.Navigation $isToggledVisible={isNavigationVisible}>
                <s.NavigationInner>
                    <s.LogoAndThemeSwitcher>
                        <s.LogoType to="/home/home.landing">Telescope</s.LogoType>
                        <ThemeSwitcher />
                    </s.LogoAndThemeSwitcher>
                    <s.SearchWrapper>
                        <Search />
                    </s.SearchWrapper>
                    <s.Menu ref={contentRef as any}>
                        <SectionTitleLink
                            to="/about/about.overview"
                            $isActiveParent={!!currentPath.match('/about') || currentPath === '/'}
                        >
                            <SectionTitleLinkText>About</SectionTitleLinkText>
                        </SectionTitleLink>
                        <SectionTitleLink
                            to="/changelog"
                            $isActiveParent={!!currentPath.match('/changelog')}
                        >
                            <SectionTitleLinkText>What's new?</SectionTitleLinkText>
                        </SectionTitleLink>
                        <Section
                            title="Foundations"
                            to="/foundations/overview/overview.overview"
                            hasActiveChild={!!currentPath.match('/foundations')}
                        >
                            <LinkItems
                                nodes={data.foundations.nodes.filter(
                                    (n) => !n.path.match('overview.overview')
                                )}
                                currentPath={currentPath}
                            />
                        </Section>
                        <SectionTitleLink
                            to="/design-tokens/design-tokens.overview"
                            $isActiveParent={
                                !!currentPath.match('/design-tokens') || currentPath === '/'
                            }
                        >
                            <SectionTitleLinkText>Design tokens</SectionTitleLinkText>
                        </SectionTitleLink>
                        <Section
                            title="Graphic elements"
                            to="/graphic-elements/overview/overview.overview"
                            hasActiveChild={!!currentPath.match('/graphic-elements')}
                        >
                            <LinkItems
                                nodes={data.graphicElements.nodes.filter(
                                    (n) => !n.path.match('overview.overview')
                                )}
                                currentPath={currentPath}
                            />
                        </Section>
                        <Section
                            to={
                                platform === 'mobile'
                                    ? '/components/mobile/overview/overview.overview'
                                    : '/components/web/overview/overview.overview'
                            }
                            title="Components"
                            hasActiveChild={!!currentPath.match('/components')}
                        >
                            <PlatformToggle platform={platform} />
                            <LinkItems nodes={componentPages} currentPath={currentPath} />
                        </Section>
                        <Section
                            title="Patterns"
                            to="/patterns/overview/overview.overview"
                            hasActiveChild={!!currentPath.match('/patterns')}
                        >
                            <LinkItems
                                nodes={data.patterns.nodes.filter(
                                    (n) => !n.path.match('overview.overview')
                                )}
                                currentPath={currentPath}
                            />
                        </Section>
                        <SectionTitleLink
                            to="/tools/tools.overview"
                            $isActiveParent={!!currentPath.match('/tools') || currentPath === '/'}
                        >
                            <SectionTitleLinkText>Tools</SectionTitleLinkText>
                        </SectionTitleLink>
                        <SectionTitleLink
                            to="/contributing/contributing.overview"
                            $isActiveParent={
                                !!currentPath.match('/contributing') || currentPath === '/'
                            }
                        >
                            <SectionTitleLinkText>Contributing</SectionTitleLinkText>
                        </SectionTitleLink>
                    </s.Menu>
                    <s.Suggestions>
                        Questions?{' '}
                        <Link
                            target="_blank"
                            href="https://getpleo.slack.com/archives/CPCFSJK1R"
                            rel="noreferrer"
                        >
                            Get in touch
                            <NewTab size={16} ml={6} />
                        </Link>
                    </s.Suggestions>
                </s.NavigationInner>
            </s.Navigation>
        </>
    )
}
