import styled from 'styled-components'

import {Button} from './components'
import type {DarkTokensAction, LightTokensAction} from './reducers'
import {SemanticToken} from './semantic-token'
import {tokens} from './tokens'
import type {ColorSchemeToken, CoreTokenType, SemanticTokenType} from './types'

const Content = styled.div`
    flex: 1;
    overflow-y: auto;
`

const EmptyState = styled.p`
    color: ${tokens.colorContentStaticQuiet};
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const SemanticTokenWrapper = styled.div`
    margin-bottom: ${tokens.spacingXXLarge};
`

export const SemanticTokens = ({
    semanticTokens,
    coreColors,
    onClearAllFilters,
    onUpdateToken
}: {
    semanticTokens: SemanticTokenType[]
    coreColors: CoreTokenType[]
    onClearAllFilters: () => void
    onUpdateToken: (action: LightTokensAction | DarkTokensAction) => void
}) => (
    <Content>
        {semanticTokens.length === 0 && (
            <EmptyState>
                <p>There are no tokens to show</p>
                <Button onClick={onClearAllFilters}>Clear all filters</Button>
            </EmptyState>
        )}
        {semanticTokens.map((token) => (
            <SemanticTokenWrapper key={token.name}>
                <SemanticToken
                    key={token.name}
                    token={token}
                    allCoreTokens={coreColors}
                    onReset={() => onUpdateToken({type: 'reset_token', name: token.name})}
                    unusedBackgroundTokens={semanticTokens.filter(
                        (t) =>
                            t.name.match(/colorBackground/) &&
                            !token.current.compatibleBackgroundTokens?.includes(`{${t.name}}`)
                    )}
                    onUpdate={(partialToken: Partial<ColorSchemeToken>) => {
                        onUpdateToken({
                            type: 'update_token',
                            name: token.name,
                            partialToken
                        })
                    }}
                />
            </SemanticTokenWrapper>
        ))}
    </Content>
)
