import t from '@pleo-io/telescope-tokens'

import {px} from './utils'

export const tokens = {
    borderStatic: t.borderStatic,
    borderStaticLoud: t.borderStaticLoud,
    borderInteractive: t.borderInteractive,
    borderInteractiveHover: t.borderInteractiveHover,
    borderInteractiveDisabled: t.borderInteractiveDisabled,
    borderTransparent: '1px solid transparent',
    spacingXSmall: t.spacing4,
    spacingSmall: t.spacing6,
    spacingMedium: t.spacing12,
    spacingXMedium: t.spacing18,
    spacingLarge: t.spacing24,
    spacingXLarge: t.spacing36,
    spacingXXLarge: t.spacing52,
    borderRadiusSmall: t.arc4,
    borderRadiusMedium: t.arc8,
    borderRadiusLarge: px(16),
    borderRadiusXXLarge: px(36),
    borderRadiusCircle: t.arc99999,
    colorBackgroundInteractive: t.colorBackgroundInteractive,
    colorBackgroundInteractiveInverse: t.colorBackgroundInteractiveInverse,
    colorBackgroundStatic: t.colorBackgroundStatic,
    colorBackgroundStaticLouder: t.colorBackgroundStaticLouder,
    colorBackgroundStaticLoudest: t.colorBackgroundStaticLoudest,
    colorBorderInteractiveHover: t.colorBorderInteractiveHover,
    colorContentStatic: t.colorContentStatic,
    colorContentStaticQuiet: t.colorContentStaticQuiet,
    colorContentNegative: t.colorContentNegative,
    colorContentPositive: t.colorContentPositive,
    colorContentInteractive: t.colorContentInteractive,
    colorContentInteractiveInverse: t.colorContentInteractiveInverse,
    colorContentInteractiveDisabled: t.colorContentInteractiveDisabled,
    fontSizeRegular: px(15),
    fontSizeLarge: px(18),
    fontSizeXLarge: px(20),
    fontWeightRegular: t.fontWeightRegular,
    fontWeightBold: t.fontWeightBold,
    transitionSpeedRegular: t.motionDurationModerate,
    opacityDisabled: t.opacity50,
    heightInteractiveSmall: px(32),
    heightInteractiveRegular: px(36),
    shadowRaise: t.shadowRaise
}
