import React from 'react'

import {useLocalStorage} from './use-local-storage'

const useFigmaLayout = (location: Location) => {
    const [localStorageFigma, setLocalStorageFigma] = useLocalStorage<boolean>(
        'figma-layout-key',
        false
    )

    React.useEffect(() => {
        if (location.search) {
            const searchParams = new URLSearchParams(location.search)
            const figmaParam = searchParams.get('figma')

            if (figmaParam === 'true') {
                setLocalStorageFigma(true)
            }
        }
    }, [location.search, localStorageFigma, setLocalStorageFigma])

    return localStorageFigma
}

export default useFigmaLayout
