import coreColorsJson from '@pleo-io/telescope-tokens/dist/tokens/color.json'
import colorSchemeDark from '@pleo-io/telescope-tokens/dist/tokens/color-scheme-dark.json'
import colorSchemeLight from '@pleo-io/telescope-tokens/dist/tokens/color-scheme-light.json'

import type {
    ColorSchemeCore,
    ColorSchemeLight,
    ColorSchemeToken,
    CoreTokenName,
    DarkTokenName,
    LightTokenName
} from './types'

export type LightTokensReducerState = ColorSchemeLight
export type LightTokensAction =
    | {
          type: 'update_token'
          name: LightTokenName
          partialToken: Partial<ColorSchemeToken>
      }
    | {type: 'reset'}
    | {type: 'reset_token'; name: LightTokenName}

export const lightTokensReducer = (state: LightTokensReducerState, action: LightTokensAction) => {
    switch (action.type) {
        case 'update_token':
            return {...state, [action.name]: {...state[action.name], ...action.partialToken}}
        case 'reset_token':
            return {...state, [action.name]: colorSchemeLight[action.name]}
        case 'reset':
            return colorSchemeLight
        default:
            return state
    }
}

export type DarkTokensReducerState = typeof colorSchemeDark
export type DarkTokensAction =
    | {
          type: 'update_token'
          name: DarkTokenName
          partialToken: Partial<ColorSchemeToken>
      }
    | {type: 'reset'}
    | {type: 'reset_token'; name: DarkTokenName}

export const darkTokensReducer = (state: DarkTokensReducerState, action: DarkTokensAction) => {
    switch (action.type) {
        case 'update_token':
            return {
                ...state,
                dark: {
                    ...state.dark,
                    [action.name]: {...state.dark[action.name], ...action.partialToken}
                }
            }
        case 'reset_token':
            return {
                ...state,
                dark: {
                    ...state.dark,
                    [action.name]: colorSchemeDark.dark[action.name]
                }
            }
        case 'reset':
            return colorSchemeDark
        default:
            return state
    }
}

export type CoreTokensState = ColorSchemeCore
export type CoreTokensAction =
    | {
          type: 'update_token'
          name: CoreTokenName
          value: string
      }
    | {type: 'reset'}
    | {type: 'reset_token'; name: CoreTokenName}

export const coreTokenReducer = (state: CoreTokensState, action: CoreTokensAction) => {
    switch (action.type) {
        case 'update_token': {
            return {...state, [action.name]: {...state[action.name], value: action.value}}
        }
        case 'reset_token':
            return {...state, [action.name]: coreColorsJson[action.name]}
        case 'reset':
            return coreColorsJson
        default:
            return state
    }
}
