import {Link as GastbyLink} from 'gatsby'
import styled, {css} from 'styled-components'

import {Badge, duration, focusRing, px, Text, tokens} from '@pleo-io/telescope'

import {
    customColorSchemeTokens,
    maxWidthContent,
    maxWidthTablet,
    minWidthDesktop,
    paddingContentSmall
} from '@/tokens'

import {heightHeaderMobile, zIndexHeaderMobile} from './header-mobile'

export const navWidth = px(300)

export const Navigation = styled.nav<{$isToggledVisible: boolean}>`
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: ${navWidth};
    height: 100%;
    background-color: ${tokens.colorBackgroundStaticLoudest};

    @media (max-width: ${maxWidthTablet}) {
        display: ${({$isToggledVisible}) => ($isToggledVisible ? 'block' : 'none')};
        padding-top: ${heightHeaderMobile};
        z-index: ${zIndexHeaderMobile - 1};
        width: 100%;
    }
`

export const NavigationInner = styled.div`
    height: 100%;
    max-width: ${maxWidthContent};
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const Menu = styled.div`
    position: relative;
    flex: 1;
    padding: 0 ${paddingContentSmall} ${tokens.spacing48} ${paddingContentSmall};
    overflow: auto;

    @media (min-width: ${minWidthDesktop}) {
        padding: 0 ${tokens.spacing48} ${tokens.spacing36} ${tokens.spacing48};
    }

    &::before {
        content: '';
        z-index: 1;
        width: 100%;
        height: ${tokens.spacing18};
        display: block;
        position: sticky;
        top: 0;
        pointer-events: none;
        background: linear-gradient(
            180deg,
            ${tokens.colorBackgroundStaticLoudest} 0%,
            ${tokens.colorBackgroundStaticLoudest} 50%,
            rgba(236 236 236 0) 100% /* Safari does not like transparent */
        );
    }
`

export const LinkItems = styled.ul`
    position: relative;
    line-height: ${tokens.lineHeight1};
    padding-left: ${tokens.spacing18};

    &::before {
        content: '';
        height: 100%;
        width: ${tokens.sizeBorderDefault};
        left: 0;
        top: 0;
        background-color: ${customColorSchemeTokens.colorBorderNavigationLinkItems};
        position: absolute;
    }
`

export const LinkItem = styled.li`
    list-style-type: none;
`

export const LinkTitle = styled.span``

export const Link = styled(GastbyLink)<{$isActiveParent: boolean}>`
    ${focusRing('regular')};
    align-items: center;
    justify-content: space-between;
    display: flex;
    color: ${tokens.colorContentInteractive};
    font-size: ${tokens.fontMedium};
    transition-timing-function: ease-in-out;
    transition-duration: ${duration.smooth};
    transition-property: color;
    padding: ${tokens.spacing8} ${tokens.spacing12};
    font-weight: ${tokens.fontWeightRegular};
    border-radius: ${tokens.arc8};

    ${({$isActiveParent}) =>
        $isActiveParent &&
        css`
            font-weight: ${tokens.fontWeightSemibold};
            background: ${tokens.colorBackgroundInteractiveSelected};
            color: ${tokens.colorContentInteractiveSelected} !important;
            pointer-events: none;
        `};

    &,
    &:visited {
        color: ${tokens.colorContentInteractive};
    }

    &:hover,
    &:visited:hover {
        color: ${tokens.colorContentInteractiveHover};

        ${LinkTitle} {
            text-decoration: underline;
        }
    }
`

export const LogoAndThemeSwitcher = styled.div`
    display: none;

    @media (min-width: ${minWidthDesktop}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${tokens.spacing48} ${tokens.spacing48} 0 ${tokens.spacing48};
        color: ${tokens.colorContentInteractive};
    }
`

export const LogoType = styled(GastbyLink)`
    display: none;
    padding: 0;

    @media (min-width: ${minWidthDesktop}) {
        color: ${tokens.colorContentInteractive};
        display: block;
        font-size: ${tokens.font2XLarge};
        font-weight: ${tokens.fontWeightBold};
        ${focusRing('inset')}

        &:hover {
            text-decoration: none;
        }
    }
`

export const SearchWrapper = styled.div`
    position: relative;

    /* In order for focus ring to not be cut off */
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 2;
    padding: ${tokens.spacing24} ${paddingContentSmall} 0 ${paddingContentSmall};

    @media (min-width: ${minWidthDesktop}) {
        padding: ${tokens.spacing24} ${tokens.spacing48} 0 ${tokens.spacing48};
    }
`

export const Suggestions = styled(Text)`
    padding: ${tokens.spacing24} ${tokens.spacing48};
    text-align: center;

    &,
    a {
        font-size: ${tokens.fontMedium};
    }
`

export const Status = styled(Badge)`
    margin-left: ${tokens.spacing8};
`
