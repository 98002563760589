import styled from 'styled-components'

import {Button} from './components'
import {tokens} from './tokens'
import type {FilterType} from './types'

type Diffs = {
    core: number
    light: number
    dark: number
}

const DiffTextToggle = styled.button<{$active: boolean}>`
    color: ${tokens.colorContentInteractive};
    font-size: ${tokens.fontSizeRegular};
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    outline: ${({$active}) => ($active ? '3px dashed' : 'none')};

    &:disabled {
        cursor: not-allowed;
        color: ${tokens.colorContentInteractiveDisabled};
    }

    &:hover:not(:disabled) {
        text-decoration: underline;
    }
`

const Container = styled.div`
    display: flex;
    padding-top: ${tokens.spacingLarge};
    box-sizing: border-box;
    gap: ${tokens.spacingMedium};
    align-items: center;
`

const getDiffTotal = (diffs: Diffs) => diffs.core + diffs.dark + diffs.light

const getDiffText = (diffs: Diffs) => {
    const totalChanges = getDiffTotal(diffs)

    if (totalChanges === 0) {
        return 'No tokens have been modified'
    }

    const diffTextParts = []
    if (diffs.core > 0) {
        diffTextParts.push(`${diffs.core} core`)
    }
    if (diffs.light > 0) {
        diffTextParts.push(`${diffs.light} light`)
    }
    if (diffs.dark > 0) {
        diffTextParts.push(`${diffs.dark} dark`)
    }

    const word = totalChanges === 1 ? 'token' : 'tokens'
    return `${totalChanges} ${word} modified (${diffTextParts.join(', ')})`
}

export const Actions = ({
    diffs,
    filter,
    toggleFilter,
    onSaveChanges,
    onRevertChanges
}: {
    filter: FilterType
    toggleFilter: (filter: FilterType) => void
    diffs: Diffs
    onSaveChanges: () => void
    onRevertChanges: () => void
}) => {
    const hasDiff = getDiffTotal(diffs) > 0
    return (
        <Container>
            <DiffTextToggle
                $active={filter === 'changed'}
                onClick={() => toggleFilter('changed')}
                disabled={!hasDiff}
                title={filter === 'changed' ? 'Clear filter' : 'Show only changes'}
            >
                {getDiffText(diffs)}
            </DiffTextToggle>
            <Button style={{marginLeft: 'auto'}} onClick={onRevertChanges} disabled={!hasDiff}>
                Revert changes
            </Button>
            <Button $variant="primary" onClick={onSaveChanges} disabled={!hasDiff}>
                Save changes to disk
            </Button>
        </Container>
    )
}
