import {Link as GatsbyLink} from 'gatsby'
import {useState} from 'react'
import styled from 'styled-components'

import {focusRing, px, tokens} from '@pleo-io/telescope'
import {ChevronDown, ChevronRight} from '@pleo-io/telescope-icons'

export const SectionTitleLink = styled(GatsbyLink)<{$isActiveParent: boolean}>`
    ${focusRing('regular')};
    width: 100%;
    font-size: ${px(15)};
    padding: ${tokens.spacing12} 0;
    color: ${tokens.colorContentInteractive};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: ${({$isActiveParent}) =>
        $isActiveParent ? tokens.fontWeightSemibold : tokens.fontWeightRegular};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
        text-decoration-color: ${tokens.colorContentInteractive};
    }

    &:visited {
        color: ${tokens.colorContentInteractive};
    }

    svg {
        margin-left: auto;
    }
`

export const SectionTitleLinkText = styled.span`
    ${SectionTitleLink}:hover & {
        text-decoration: underline;
    }
`

type Props = {
    title: string
    children: any
    to: string
    hasActiveChild: boolean
}
const Section = ({title, children, to, hasActiveChild = false}: Props) => {
    const [isExpanded, setIsExpanded] = useState(hasActiveChild)
    return (
        <>
            <SectionTitleLink
                to={to}
                $isActiveParent={hasActiveChild}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <SectionTitleLinkText>{title}</SectionTitleLinkText>
                {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
            </SectionTitleLink>
            {isExpanded && <div>{children}</div>}
        </>
    )
}

export default Section
