import styled, {css, keyframes} from 'styled-components'

import {Actions} from './actions'
import {CoreTokens} from './core-tokens'
import {Header} from './header'
import type {CoreTokensAction, LightTokensAction} from './reducers'
import {SemanticTokens} from './semantic-tokens'
import {tokens} from './tokens'
import type {CoreTokenType, FilterType, Mode, SemanticTokenType} from './types'
import {px} from './utils'

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const colorBackgroundTransparent = 'rgba(0, 0, 0, 0.1)'
const dottedBackground = css`
    background-color: ${colorBackgroundTransparent};
    background-image: radial-gradient(
            ${tokens.colorContentStaticQuiet} ${px(0.5)},
            transparent ${px(0.5)}
        ),
        radial-gradient(
            ${tokens.colorContentStaticQuiet} ${px(0.5)},
            ${colorBackgroundTransparent} ${px(0.5)}
        );
    background-size: ${px(20)} ${px(20)};
    background-position: 0 0, ${px(10)} ${px(10)};
`

const Container = styled.div`
    ${dottedBackground};
    height: 100vh;
    padding: ${px(24)};
    cursor: default;
    animation: ${fadeIn} 0.5s ease-in forwards;

    *,
    * &,
    *::before,
    *::after {
        box-sizing: border-box;
    }
`

const Inner = styled.div`
    display: flex;
    height: 100%;
    background-color: ${tokens.colorBackgroundStatic};
    box-shadow: 0 0 ${tokens.borderRadiusXXLarge} rgb(0 0 0 / 15%);
    border-radius: ${tokens.borderRadiusXXLarge};
`

const Main = styled.main`
    padding: ${px(48)};
    flex: 1;
    display: flex;
    flex-direction: column;
`

const Aside = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${px(48)} ${px(48)} ${px(48)} 0;
    flex: 0 0 ${px(440)};
`

const getFilteredTokens = (displayTokens: SemanticTokenType[], filter: FilterType) => {
    return displayTokens
        .map((token) => ({
            ...token,
            checks: token.checks?.filter((check) => {
                if (filter === 'changed') {
                    return (
                        check.apca.current.score !== check.apca.stored.score ||
                        check.wcag.current.score !== check.wcag.stored.score ||
                        check.status !== 'existing'
                    )
                } else if (filter === 'acpa:fail') {
                    return check.apca.current.status === 'fail'
                } else if (filter === 'acpa:pass') {
                    return check.apca.current.status === 'pass'
                } else if (filter === 'wcag:fail') {
                    return check.wcag.current.status === 'fail'
                } else if (filter === 'wcag:pass') {
                    return check.wcag.current.status === 'pass'
                } else if (filter === 'apca:not-available') {
                    return check.apca.current.status === 'not-available'
                } else if (filter === 'wcag:not-available') {
                    return check.wcag.current.status === 'not-available'
                }

                return true
            })
        }))
        .filter((token) => {
            if (
                filter === '' ||
                token.current.contrastRequirements?.apca !==
                    token.stored.contrastRequirements?.apca ||
                token.current.contrastRequirements?.wcag !== token.stored.contrastRequirements?.wcag
            ) {
                return true
            }

            return token.checks && token.checks.length > 0
        })
}

export const App = ({
    coreColorsDiffs,
    coreDisplayTokens,
    darkColorsDiffs,
    displayTokens,
    filter,
    lightColorsDiffs,
    mode,
    onToggleFilter,
    revertChanges,
    saveChanges,
    setFilter,
    setMode,
    updateCoreToken,
    updateSemanticToken
}: {
    coreColorsDiffs: CoreTokenType[]
    coreDisplayTokens: CoreTokenType[]
    darkColorsDiffs: SemanticTokenType[]
    displayTokens: SemanticTokenType[]
    filter: FilterType
    lightColorsDiffs: SemanticTokenType[]
    mode: Mode
    onToggleFilter: (filter: FilterType) => void
    revertChanges: () => void
    saveChanges: () => void
    setFilter: (filter: FilterType) => void
    setMode: (mode: Mode) => void
    updateCoreToken: (token: CoreTokensAction) => void
    updateSemanticToken: (token: LightTokensAction) => void
}) => {
    const filteredSemanticTokens = getFilteredTokens(displayTokens, filter)

    const filteredCoreTokens = coreDisplayTokens.filter((token) => {
        if (filter !== 'changed') {
            return true
        }

        return token.value !== token.storedValue
    })

    return (
        <Container>
            <Inner>
                <Main>
                    <Header
                        toggleFilter={onToggleFilter}
                        filter={filter}
                        mode={mode}
                        setMode={setMode}
                        displayTokens={displayTokens}
                    />
                    <SemanticTokens
                        onUpdateToken={updateSemanticToken}
                        onClearAllFilters={() => setFilter('')}
                        coreColors={coreDisplayTokens}
                        semanticTokens={filteredSemanticTokens}
                    />
                    <Actions
                        filter={filter}
                        toggleFilter={onToggleFilter}
                        diffs={{
                            core: coreColorsDiffs.length,
                            light: lightColorsDiffs.length,
                            dark: darkColorsDiffs.length
                        }}
                        onSaveChanges={saveChanges}
                        onRevertChanges={revertChanges}
                    />
                </Main>
                <Aside>
                    <CoreTokens
                        coreColors={filteredCoreTokens}
                        onUpdateCoreToken={updateCoreToken}
                    />
                </Aside>
            </Inner>
        </Container>
    )
}
