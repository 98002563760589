import chroma from 'chroma-js'

import coreColors from '@pleo-io/telescope-tokens/dist/tokens/color.json'

import type {
    Check,
    ColorSchemeToken,
    ContrastCheck,
    ContrastRequirements,
    CoreTokenName,
    CoreTokenType
} from './types'
import type {ColorSchemeObject} from './types'

type CompatibleBackgroundToken = {
    name: string
    status: 'added' | 'removed' | 'existing'
}

export const getContrastChecks = ({
    current,
    stored,
    allCoreTokens,
    currentColorScheme
}: {
    current: ColorSchemeToken
    stored: ColorSchemeToken
    allCoreTokens: CoreTokenType[]
    currentColorScheme: ColorSchemeObject
}): Check[] => {
    if (!('compatibleBackgroundTokens' in current)) {
        return []
    }

    const coreToken = allCoreTokens.find((t) => `{${t.name}}` === current.value)
    const value = coreToken?.value || current.value
    const coreTokenStored = coreColors[stored.value.replace(/{|}/g, '') as CoreTokenName]
    const storedValue = coreTokenStored?.value || current.value

    const requirements = current.contrastRequirements
    const compatibleBackgroundTokens = getCompatibleBackgroundTokens(current, stored)

    const checks = compatibleBackgroundTokens?.map((compatibleBackgroundToken) => {
        const backgroundToken = currentColorScheme[compatibleBackgroundToken.name]

        if (!backgroundToken) {
            throw new Error('No backgroundToken found')
        }

        const color = value
        const backgroundColor =
            allCoreTokens.find((t) => `{${t.name}}` === backgroundToken.value)?.value ||
            backgroundToken.value

        if (!backgroundColor) {
            throw new Error(
                `No background color found for ${compatibleBackgroundToken.name}: ${color}`
            )
        }

        return {
            status: compatibleBackgroundToken.status,
            backgroundToken: {name: compatibleBackgroundToken.name, value: backgroundToken.value},
            apca: {
                current: getAPCAScore({color, backgroundColor, requirements}),
                stored: getAPCAScore({color: storedValue, backgroundColor, requirements})
            },
            wcag: {
                current: getWCAGScore({color, backgroundColor, requirements}),
                stored: getWCAGScore({color: storedValue, backgroundColor, requirements})
            },
            color,
            backgroundColor
        }
    })

    return checks.sort((a, b) => a.backgroundToken.name.localeCompare(b.backgroundToken.name))
}

function uniqueOnly(value: string, index: number, array: string[]) {
    return array.indexOf(value) === index
}

const getCompatibleBackgroundTokens = (
    currentToken: ColorSchemeToken,
    storedToken: ColorSchemeToken
): CompatibleBackgroundToken[] => {
    const current = currentToken.compatibleBackgroundTokens || []
    const stored = storedToken.compatibleBackgroundTokens || []
    const all = current.concat(stored).filter(uniqueOnly).sort()

    return all.map((name) => {
        const wasAdded = current.includes(name) && !stored.includes(name)
        const wasRemoved = !current.includes(name) && stored.includes(name)
        return {
            name: name.replace(/{|}/g, ''),
            status: wasAdded ? 'added' : wasRemoved ? 'removed' : 'existing'
        }
    })
}

const getAPCAScore = ({
    color,
    backgroundColor,
    requirements
}: {
    color: string
    backgroundColor: string
    requirements?: ContrastRequirements
}): ContrastCheck => {
    if (backgroundColor.match(/rgba/)) {
        return {score: 'N/A', status: 'not-available'}
    }
    // @ts-ignore - contrastAPCA is new, chroma types are not up to date
    const apca = Math.abs(chroma.contrastAPCA(color, backgroundColor).toFixed(2))

    if (!requirements?.apca) {
        return {score: apca, status: 'not-defined'}
    }
    return {score: apca, status: apca > requirements.apca ? 'pass' : 'fail'}
}

const getWCAGScore = ({
    color,
    backgroundColor,
    requirements
}: {
    color: string
    backgroundColor: string
    requirements?: ContrastRequirements
}): ContrastCheck => {
    if (backgroundColor.match(/rgba/)) {
        return {score: 'N/A', status: 'not-available'}
    }
    const wcag = Number(chroma.contrast(color, backgroundColor).toFixed(2))

    if (!requirements?.wcag) {
        return {score: wcag, status: 'not-defined'}
    }

    return {score: wcag, status: wcag > requirements.wcag ? 'pass' : 'fail'}
}
